<template>
    <section class="section">
        <div class="container">
            <div class="m-b-30">
                <button class="btn btn-warning" @click="goBack">Back</button>
            </div>
            <h4 class="event-title">{{ event.title }}</h4>
            <div class="card">
                <div class="card__body">
                    <Placeholder v-if="isLoading" />
                    <div class="event" v-else>
                        <div class="row">
                            <div class="col-md-5">
                                <div class="vote-nominee">
                                    <div class="photo">
                                        <img :src="nominee.photo || placeholder" class="vote-photo" />
                                    </div>
                                    <div class="name">{{ nominee.name }}</div>
                                    <div class="votes" v-show="event.live_votes">
                                        {{ nominee.votes }}
                                        <small>votes</small>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-7">
                                <div class="event-details">
                                    <form @submit.prevent="onCheckout">
                                        <div class="form-group">
                                            <label for="amount">Enter the number of votes you
                                                want to purchase. A vote costs
                                                GHS {{ event.price }}</label>
                                            <div class="vote-box">
                                                <input type="number" class="votes" id="amount" placeholder="Votes"
                                                    min="1" v-model.number="checkout.votes
                                                        " @focus="onAmountFocused" @blur="onAmountBlurred" />
                                            </div>
                                        </div>

                                        <div class="form-group" v-show="event.enable_bulk_votes">
                                            <label for="">Bulk Votes</label>
                                            <div class="bulk-votes">
                                                <div class="item" v-for="(bulk, i) in event.bulk_vote_plans" :key="i"
                                                    :class="bulk.selected ? 'selected' : ''"
                                                    @click="onBulkVoteSelected(bulk)">
                                                    <div>{{ bulk.name }}</div>
                                                    <div>
                                                        GHS {{ bulk.amount }}
                                                    </div>
                                                    <div>
                                                        {{ bulk.votes }} Votes
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group">
                                            <div class="vote-pricing">
                                                <div class="segment">
                                                    <div class="value">
                                                        {{
                                                            checkout.votes || 0
                                                        }}
                                                    </div>
                                                    <div class="label">
                                                        votes
                                                    </div>
                                                </div>
                                                <div class="segment">
                                                    <div class="value">
                                                        {{ totalAmountDisplay }}
                                                    </div>
                                                    <div class="label">GHS</div>
                                                </div>
                                                <div class="segment">
                                                    <div class="value">
                                                        {{ convertGHSToUSD }}
                                                    </div>
                                                    <div class="label">USD</div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="form-group">
                                            <div class="check-box">
                                                <input type="checkbox" id="isAnonymous" v-model="checkout.isAnonymous
                                                    " @change="onAnonymousChanged" />
                                                <label for="isAnonymous">Anonymous</label>
                                            </div>
                                        </div>

                                        <div v-if="!checkout.isAnonymous" class="form-group">
                                            <div class="form-group">
                                                <label for="">Email
                                                    <em>(required)</em></label>
                                                <input type="email" class="form-control" v-model="checkout.email"
                                                    required />
                                            </div>

                                            <div class="form-group">
                                                <label for="">Name</label>
                                                <input type="text" class="form-control" v-model="checkout.name" />
                                            </div>
                                        </div>

                                        <div class="form-group">
                                            <div class="event-radio">
                                                <label>
                                                    <input type="radio" value="MOMO" v-model="pay_with"
                                                        name="payment-origin" checked />
                                                    <span>Mobile Money</span>
                                                </label>
                                                <div class="
                                                        row
                                                        m-b-20
                                                        nalo-payment
                                                    " v-if="pay_with == 'MOMO'">
                                                    <div class="col-md-4">
                                                        <label class="nalo-label">Network</label>
                                                        <select v-model="momo_network
                                                            " class="
                                                                form-control
                                                                nalo-input
                                                            " required>
                                                            <option>
                                                                AIRTELTIGO
                                                            </option>
                                                            <option>MTN</option>
                                                            <option>
                                                                VODAFONE
                                                            </option>
                                                        </select>
                                                    </div>
                                                    <div class="col-md-8">
                                                        <label class="nalo-label">Wallet
                                                            Number</label>
                                                        <input v-model="momo_wallet
                                                            " type="text" class="
                                                                form-control
                                                                nalo-input
                                                            " maxlength="10" placeholder="Example: 0248985021"
                                                            required />
                                                    </div>
                                                    <div class="col-md-12">
                                                        <p class="
                                                                m-t-10
                                                                notice
                                                            ">
                                                            Please note that an
                                                            One Time Pin (OTP)
                                                            will be sent to your
                                                            Wallet Number. This
                                                            is a USSD code that
                                                            initializes with
                                                            payment prompt.
                                                            Kindly dial the code
                                                            and a payment prompt
                                                            will be appear on
                                                            your phone to
                                                            complete the
                                                            payment. Thank you.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="event-radio">
                                                <label>
                                                    <input type="radio" value="CARD" v-model="pay_with"
                                                        name="payment-origin" />
                                                    <span>Card Payment (International)</span>
                                                </label>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <button class="
                                                    btn btn-success btn-block
                                                    mt-5
                                                " type="submit" :disabled="isVoting">
                                                Vote Now
                                                <Spinner v-if="isVoting" />
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
import { doc, getDoc, getFirestore, setDoc } from "@firebase/firestore";
import { getFunctions, httpsCallable } from "@firebase/functions";
import { parsePhoneNumber } from "awesome-phonenumber";
import { nanoid } from 'nanoid';
import dayjs from "dayjs";
const db = getFirestore();
const functions = getFunctions();
export default {
    data() {
        return {
            event: {
                title: "",
                type: "",
                date: "",
                organizer: {
                    name: "",
                    id: "",
                },
                bulk_vote_plans: [],
                enable_bulk_votes: false,
                paystack: {
                    percentage_charge: 0,
                }
            },
            checkout: {
                value: null,
                amount: 0,
                votes: null,
                vote_type: null,
                bulk_vote_type: null,
                email: null,
                name: null,
                telephone: null,
                isAnonymous: false,
                type: "CONTEST",
            },
            nominee: {},
            placeholder: "/img/placeholder.jpg",
            placeholder_square: "/img/placeholder.jpg",
            isVoting: false,
            isLoading: false,
            payment_origin: "international",
            exchange_rate: 0,
            preview: {},
            pay_with: "MOMO",
            momo_network: null,
            momo_wallet: null,

            korba_methods: [
                {
                    name: "MTN",
                    code: "MTN",
                },
                {
                    name: "VODAFONE",
                    code: "VOD",
                },
                {
                    name: "AIRTEL",
                    code: "AIR",
                },
                {
                    name: "TIGO",
                    code: "TIG",
                },
                {
                    name: "VISA / MASTER CARD",
                    code: "CRD",
                },
            ],
        };
    },

    methods: {

        goBack() {
            this.$router.go(-1);
        },

        onBulkVoteSelected(bulk) {
            this.checkout.value = bulk.amount;
            this.checkout.amount = bulk.amount * 100;
            this.checkout.votes = bulk.votes;
            this.checkout.vote_type = "BULK_VOTE";
            this.checkout.bulk_vote_type = bulk.name.toLowerCase();
            this.event.bulk_vote_plans
                .filter((b) => b.name != bulk.name)
                .map((b) => (b.selected = false));
            this.event.bulk_vote_plans
                .filter((b) => b.name == bulk.name)
                .map((b) => (b.selected = true));
        },

        async onCheckout() {

            this.isVoting = true;

            const reference = uuidv4();

            const { momo_network, momo_wallet, pay_with } = this;

            const { email, votes, vote_type, bulk_vote_type } = this.checkout;

            const organizer_percentage = this.event.paystack.percentage_charge;

            const votes_cast = Number(votes);

            const percentage_charge = Number(organizer_percentage);

            const actual_amount = Number(this.totalAmount);

            const nomineeId = this.$route.params.nomineeId;

            const nominee = this.nominee;

            let network = null;

            let wallet = null;

            if (pay_with == "MOMO") {
                if (momo_network == null || momo_network == "") {
                    return this.$toast.error("Please select a network");
                } else {
                    network = momo_network.toUpperCase();
                }
                const PN = parsePhoneNumber(momo_wallet, "GH");
                if (!PN.isValid()) {
                    return this.$toast.error("Please enter a valid phone number");
                } else {
                    wallet = PN.getNumber().replace(/\s+/g, "").replace("+", "");
                }
            }

            // TRANSACTION PAYLOAD
            const transaction_payload = {
                reference,
                email,
                actual_amount,
                amount: actual_amount,
                amount_usd: this.convertGHSToUSD,
                event_id: this.$route.params.eventId,
                event_title: this.event.title,
                event_date: this.event.date,
                isAnonymous: this.checkout.isAnonymous,
                name: this.checkout.name,
                telephone: this.checkout.telephone,
                nominee_id: this.$route.params.nomineeId,
                organizer_id: this.event.organizer.id,
                payment_origin: this.payment_origin,
                type: "CONTEST",
                status: "PENDING",
                event_type: "CONTEST",
                channel: "WEB",
                gateway: (pay_with == "MOMO") ? "NALOSOLUTIONS" : "PAYSTACK",
                is_completed: false,
                nominee,
                nomineeId,
                votes,
                vote_type,
                bulk_vote_type,
                votes_cast,
                percentage_charge,
                created_at: dayjs().toDate(),
                updated_at: dayjs().toDate(),
                pay_with,
                momo: {
                    network,
                    wallet,
                },
            };

            // TRANSACTION REFERENCE
            const ref = doc(db, "transactions", reference);

            // ADD TRANSACTION RECORD
            await setDoc(ref, transaction_payload);

            if (pay_with === "MOMO") {

                const nalo_payload = {
                    amount: actual_amount,
                    payby: network,
                    order_id: reference,
                    customerNumber: wallet,
                    customerName: this.checkout.name,
                    item_desc: "Payment for votes",
                    reference,
                    event_type: "CONTEST",
                };

                const payWithNaloSolutions = httpsCallable(functions, "payWithNaloSolutions");

                const { data: nalosolutions_response } = await payWithNaloSolutions(nalo_payload);

                const { status: nalo_status, message: nalo_message } = nalosolutions_response;

                this.$toast[nalo_status ? "success" : "error"](nalo_message);

                // clear the form
                if (nalo_status) {
                    this.pay_with = "MOMO";
                    this.momo_network = null;
                    this.momo_wallet = null;
                    this.checkout = {
                        value: null,
                        amount: 0,
                        votes: null,
                        vote_type: null,
                        bulk_vote_type: null,
                        email: null,
                        name: null,
                        telephone: null,
                        isAnonymous: false,
                        type: "CONTEST",
                    };
                }

            } else {

                // pay with paystack
                const paystack_payload = {
                    reference,
                    amount: actual_amount * 100,
                    email,
                };

                const payWithPaystack = httpsCallable(functions, "payWithPaystack")
                const { data: paystackData } = await payWithPaystack(paystack_payload);

                // console.log(paystackData);

                if (paystackData) {
                    const { authorization_url } = paystackData;
                    window.location.href = authorization_url;
                }


            }

            this.isVoting = false;

        },

        async getNominee() {
            const eventId = this.$route.params.eventId;
            const nomineeId = this.$route.params.nomineeId;
            const nomineeRef = doc(
                db,
                "events/" + eventId + "/nominees/" + nomineeId
            );
            const snapshot = await getDoc(nomineeRef);
            const nominee = snapshot.data();
            return nominee;
        },

        async getEvent() {
            const eventId = this.$route.params.eventId;
            const ref = doc(db, `events/${eventId}`);
            const snapshot = await getDoc(ref);
            const data = snapshot.data();
            data.expiry = dayjs(data.date.toDate()).format("DD MMMM, YYYY");
            data.company = data.organizer.name;
            return data;
        },

        async getExchangeRate() {
            const ref = doc(db, "exchange_rates/USD_GHS");
            const snapshot = await getDoc(ref);
            const data = snapshot.data();
            const { result } = data;
            this.exchange_rate = result;
        },

        initialize() {
            this.isLoading = true;
            const nominees = this.getNominee();
            const event = this.getEvent();
            Promise.all([event, nominees])
                .then((result) => {
                    this.isLoading = false;
                    this.event = result[0];
                    this.nominee = result[1];
                    this.checkout.type = "CONTEST";
                })
                .catch(() => {
                    this.isLoading = false;
                });
        },

        onAnonymousChanged($event) {
            const { target } = $event;
            const { checked } = target;
            if (checked) {
                let email = nanoid();
                email = email.replace(/[^a-zA-Z0-9.]/g, '');
                let _email = `anonymous.${email}@smartkastghana.com`.toLowerCase();
                this.checkout.email = _email;
                this.checkout.name = "Anonymous";
            } else {
                this.checkout.email = "";
                this.checkout.name = "";
            }
        },

        onAmountFocused() {
            this.checkout.vote_type = "DIRECT_VOTE";
            this.checkout.value = null;
            this.checkout.amount = null;
            this.checkout.votes = null;
            this.checkout.bulk_vote_type = null;
            this.event.bulk_vote_plans.map((b) => (b.selected = false));
        },

        onAmountBlurred() {
            const value = this.checkout.value;
            if (value != null) {
                const formattedAmount = value * 100;
                this.checkout.amount = Number(formattedAmount);
            } else {
                this.checkout.amount = null;
            }
        },
    },

    computed: {
        totalAmount() {
            const { event, checkout } = this;
            const { vote_type, votes, value } = checkout;
            const { price } = event;
            let total_price = 0;
            if (vote_type === "DIRECT_VOTE") {
                total_price = (votes * price) || 0;
            } else {
                total_price = value || 0;
            }
            return total_price;
        },
        totalAmountDisplay() {
            return Number(this.totalAmount).toFixed(2);
        },
        convertGHSToUSD() {
            const { exchange_rate } = this;
            return Number(this.totalAmount / exchange_rate).toFixed(2);
        },
    },

    created() {
        this.initialize();
        this.getExchangeRate();
    },
};
</script>
